import { VacancyApiFactory, V1TenantWebsite, V1Group, V1Place, V1VacantStatus } from '@vacancorp/enterprise-for-client.vacanservice.com'
import { TenantWebsite, Group, Place, VacantStatus } from '@/models'
import { api, ApiResponse, createApiError } from './base'

function createPlaces(rawPlaces?: V1Place[]): Place[] {
  if (!rawPlaces) return []

  return rawPlaces.map((raw: V1Place): Place => {
    return new Place({
      id: raw.id,
      floor: raw.floor,
      name: raw.name,
      stallsLabel: raw.stallsLabel
    })
  })
}

function createGroups(rawGroups?: V1Group[]): Group[] {
  if (!rawGroups) return []

  return rawGroups.map((raw: V1Group): Group => {
    return new Group({
      name: raw.name,
      groupType: raw.groupType,
      groupCode: raw.groupCode,
      places: createPlaces(raw.places)
    })
  })
}

function createTenantWebsite(raw?: V1TenantWebsite): TenantWebsite | undefined {
  if (!raw) return undefined

  return new TenantWebsite({
    name: raw.name,
    logoUrl: raw.logoUrl,
    groups: createGroups(raw.groups)
  })
}

function createTenantWebsiteStatuses(rawStatuses?: { [key: string]: V1VacantStatus }): VacantStatus[] {
  if (!rawStatuses) return []

  return Object.keys(rawStatuses).map((key: string): VacantStatus => {
    const raw: V1VacantStatus = rawStatuses[key]
    return new VacantStatus({
      id: key,
      total: raw.total,
      vacant: raw.vacant,
      vacantLabel: raw.vacantLabel,
      textColor: raw.textColor,
    })
  })
}

export const NewVacanciesApiFunc = () => {
  const webAdapter = VacancyApiFactory(undefined, api.defaults.baseURL, api)

  return {
    getTenantWebsite: async (websiteCode: string, language = "ja_Jp"): Promise<ApiResponse<TenantWebsite | undefined>> => {
      let data, err

      try {
        const res = await webAdapter.getTenantWebsite(websiteCode, {language})
        data = createTenantWebsite(res.data)
      } catch (e) {
        data = undefined
        err = createApiError(e)
      }

      return {
        data,
        err
      }
    },

    getPlaceStatuses: async (websiteCode: string, placeIds: string[], language: string): Promise<ApiResponse<VacantStatus[]>> => {
      let data, err

      try {
        const d = { data: {
          places: placeIds,
          language,
        }}
        const res = await webAdapter.getTenantWebsiteStatus(websiteCode, d)
        data = createTenantWebsiteStatuses(res.data.statuses)
      } catch (e) {
        data = [] as VacantStatus[]
        err = createApiError(e)
      }

      return {
        data,
        err
      }
    }
  }
}