import { SessionToken } from './session-token'

export class TenantSession {
  userId?: string
  tenantId?: string
  role?: string
  token?: SessionToken

  constructor(params?: Partial<TenantSession>) {
    Object.assign(this, params)
  }
}
