import { Place } from './place'

export class Group {
  name?: string;
  groupType?: string;
  groupCode?: string;
  places?: Array<Place>;

  constructor(params?: Partial<Group>) {
    Object.assign(this, params)
  }
}
