import { NewSessionsApiFunc, ApiError } from "@/api";

export default class AuthModule {
  accessToken?: string;
  authorizedWebsiteList: string[] = []; // TODO: 本当は ここでもトークンの仕組みつくるべき。この仕組みだと、一度アクセスした website は未来永劫アクセスできてしまう。
  refreshToken?: string;
  role?: string;
  websiteCode?: string;

  async login(
    this: AuthModule,
    websiteCode: string,
    passcode: string | undefined,
    loginId: string | undefined,
    password: string | undefined
  ): Promise<ApiError | undefined> {
    const { data, err } = await NewSessionsApiFunc().authenticateTenant(
      websiteCode,
      passcode,
      loginId,
      password
    );
    if (err != null) {
      return err;
    }
    this.accessToken = data?.token?.accessToken;
    this.authorizedWebsiteList.push(websiteCode);
    this.refreshToken = data?.token?.refreshToken;
    this.role = data?.role || "tenant";
    this.store();

    return undefined;
  }

  isAuthed(this: AuthModule): boolean {
    return this.accessToken && this.role && this.websiteCode ? true : false;
  }

  // パスコードでの認可とユーザー認証での認可が同じエンドポイントになってしまってるが、分離したらもうちょっとスッキリしそう。。
  public isAuthorizedWebsite = (website: string): boolean => {
    return this.authorizedWebsiteList === undefined
      ? false
      : this.authorizedWebsiteList.includes(website);
  };

  setWebsiteCode(websiteCode: string): void {
    this.websiteCode = websiteCode;
    this.store();
  }

  async refresh(this: AuthModule): Promise<ApiError | undefined> {
    const { data, err } = await NewSessionsApiFunc().refreshToken(
      this.refreshToken
    );

    if (err != null) {
      this.clear();
      return err;
    }
    this.accessToken = data?.accessToken;
    this.refreshToken = data?.refreshToken;
    this.store();

    return undefined;
  }

  store() {
    localStorage.auth = JSON.stringify({
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
      role: this.role,
      websiteCode: this.websiteCode,
    });

    // パスコードの保持期間はセッションストレージの単位程度にしたいため。("毎回"入力させるという仕様)
    sessionStorage.auth = JSON.stringify({
      authorizedWebsiteList: this.authorizedWebsiteList,
    });
  }

  restore() {
    const auth = localStorage.auth && JSON.parse(localStorage.auth);
    const sessionStorageAuth =
      sessionStorage.auth && JSON.parse(sessionStorage.auth);

    if (!auth) {
      return;
    }
    this.accessToken = auth.accessToken;
    this.authorizedWebsiteList =
      sessionStorageAuth?.authorizedWebsiteList || [];
    this.refreshToken = auth.refreshToken;
    this.role = auth.role;
    this.websiteCode = auth.websiteCode;
  }

  clear() {
    localStorage.removeItem("auth");
    sessionStorage.removeItem("auth");
    this.accessToken = undefined;
    this.authorizedWebsiteList = [];
    this.refreshToken = undefined;
    this.role = undefined;
    this.websiteCode = undefined;
  }

  clearWithoutWebsite() {
    localStorage.removeItem("auth");
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.role = undefined;
    this.websiteCode = undefined;
  }
}
