import { PasswordApiFactory } from '@vacancorp/enterprise-for-client.vacanservice.com'
import { api, ApiResponse, createApiError } from './base'

export const NewPasswordApiFunc = () => {
  const webAdapter = PasswordApiFactory(undefined, api.defaults.baseURL, api)

  return {
    forgotPassword: async (mail?: string): Promise<ApiResponse<void>> => {
      let data, err

      try {
        await webAdapter.forgotPassword({
          data: {
            email: mail
          }
        })
      } catch (e) {
        data = undefined
        err = createApiError(e)
      }

      return {
        data,
        err,
      }
    },

    resetPassword: async (mail?: string, confirmationCode?: string, newPassword?: string): Promise<ApiResponse<void>> => {
      let data, err

      try {
        await webAdapter.resetPassword({
          data: {
            email: mail,
            confirmationCode,
            newPassword
          }
        })
      } catch (e) {
        data = undefined
        err = createApiError(e)
      }

      return {
        data,
        err,
      }
    }
  }
}