





import Vue from 'vue'

export default Vue.extend({
  name: 'BaseErrorMessage',

  computed: {
    hasErrorMessage(): boolean {
      return !!this.$error.errMessage
    },

    errMessage(): string | undefined {
      return this.$error.errMessage
    },
  },

})
