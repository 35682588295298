import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import { hasVacanTenantCode, NewTenantsApiFunc, NewVacanciesApiFunc } from '@/api'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const getTenantWebsite: () => Promise<void> = async () => {
  const websiteCode = Vue.prototype.$auth.websiteCode as string
  const res = await NewVacanciesApiFunc().getTenantWebsite(websiteCode)

  if (res.err != null && Vue.prototype.$handleApiError) {
    Vue.prototype.$handleApiError(res.err, getTenantWebsite)
    return
  }
}

router.beforeEach(async (to, form, next) => {
  Vue.prototype.$error.clearAll()
  Vue.prototype.$auth.restore()

  const role: string | undefined = Vue.prototype.$auth.role
  const isAuthed: boolean = Vue.prototype.$auth.isAuthed()
  const selectedWebsiteCode: string = Vue.prototype.$auth.websiteCode || to.params.websiteCode
  if (isAuthed && selectedWebsiteCode) {
    getTenantWebsite()
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthed) {
    return next({ name: 'login', query: { redirect: to.fullPath }, params: { websiteCode: selectedWebsiteCode } })
  }

  if (to.fullPath === '/') {
    return role === 'admin' ? next({ path: '/admin/companies'}) : next({ path: '/company/analytics'})
  }

  const canAccess: boolean = to.matched.every(record => {
    if (!record.meta.canAccessRole) return true

    // adminがcompanyのページにアクセスするときは、VACANCompanyIdを持つようにする
    const adminAccessToCompanyPage: boolean = role === 'admin' && record.meta.canAccessRole.includes('company')
    if (adminAccessToCompanyPage && !hasVacanTenantCode()) {
      return false
    }

    const canAccessRole: boolean = record.meta.canAccessRole.includes(role)
    return canAccessRole
  })

  if (!canAccess) {
    next({ path: '/login', query: { redirect: to.fullPath } })
    return Vue.prototype.$error.errMessage = "不正なアクセスです"
  }

  return next()
})

export default router
