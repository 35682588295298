









import Vue from 'vue'
import TheHeader from '@/components/TheHeader.vue'

export default Vue.extend({
  name: 'App',

  components: { TheHeader },

  computed: {
    shouldShowHeader() {
      return !!this.$route.name && ![
        'login',
        'password-forgot',
        'password-reset',
        'password-set'
      ].includes(this.$route.name)
    }
  }
})
