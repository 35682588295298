import { Group } from './group'

export class TenantWebsite {
  name?: string
  logoUrl?: string
  groups?: Array<Group>

  constructor(params?: Partial<TenantWebsite>) {
    Object.assign(this, params)
  }
}
