






























import Vue from 'vue'
import Logo from '@/components/TheLogo.vue'
import { NewSessionsApiFunc } from '@/api'
import BaseDropDown, { OptionItem } from '@/components/BaseDropDown.vue'

export type DataType = {
  userOptions: OptionItem[];
  userMenuShowing: boolean;
  companyMenuShowing: boolean;
}

export default Vue.extend({
  name: 'TheHeader',

  components: { Logo, BaseDropDown },

  data(): DataType {
    const clickAction = async () => {
      if (this.$error.networkErr) {
        return
      }

      const { err } = await NewSessionsApiFunc().logout(this.$auth.accessToken)
      if (err != null) if (err != null) {
        this.$handleApiError(err, clickAction)
        return
      }
      this.$auth.clear()
      this.$router.push({name: 'login'})
    }

    return {
      userOptions: [
        {
          label: 'ログアウト',
          classes: [],
          clickAction,
        },
      ],
      userMenuShowing: false,
      companyMenuShowing: false
    }
  },

  computed: {
    shouldShowUserMenu(): boolean {
      return !!this.$auth.isAuthed
    }
  },

  methods: {
    reload(): void {
      (this.$error.reloadFunc && this.$error.reloadFunc()) || location.reload()
    }
  }
})
