import Axios, { AxiosInstance, AxiosError } from 'axios'

const basePath = process.env.VUE_APP_WEBADAPTER_URL

export const api: AxiosInstance = Axios.create({
  baseURL: basePath,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: 'json'
})

export const setAccessToken = (accessToken: string | undefined) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

export const setVacanTenantCode = (companyId: string): void => {
  api.defaults.headers.common['X-VACAN-Tenant-Code'] = companyId
}

export const hasVacanTenantCode = (): boolean => {
  return api.defaults.headers.common['X-VACAN-Tenant-Code'] !== null
}

export interface ErrorDetail {
  resource: string;
  field: string;
  message: string;
}

export interface ApiError {
  status?: number;
  message?: string;
  errors?: ErrorDetail[];
  networkErr: boolean;
}

export interface ApiResponse<T> {
  data: T;
  err?: ApiError;
}

export interface ListResponse<T> {
  list: T[];
  currentPage: number;
  totalPage: number;
}

export function createApiError(e: AxiosError): ApiError {
  const networkErr: boolean = e.toString() === 'Error: Network Error' ? true : false
  return {
    status: e?.response?.status,
    message: e?.response?.data?.message,
    errors: e?.response?.data?.errors,
    networkErr,
  }
}