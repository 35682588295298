import Vue from "vue";
const routes = [
  { path: "/" },

  /**
   * エラー
   */
  {
    path: "/404",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/NotFoundScreen.vue"),
  },

  {
    path: "/password-forgot",
    name: "password-forgot",
    component: () =>
      import(
        /* webpackChunkName: "password-forgot" */ "@/views/PasswordForgotScreen.vue"
      ),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "@/views/PasswordResetScreen.vue"
      ),
  },
  {
    path: "/password-set",
    name: "password-set",
    component: () =>
      import(
        /* webpackChunkName: "password-set" */ "@/views/PasswordSetScreen.vue"
      ),
  },

  /**
   * 未ログイン
   */
  {
    path: "/:websiteCode/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginScreen.vue"),
  },

  /**
   * ログイン後
   */
  {
    path: "/:websiteCode",
    name: "tenant-website-detail",
    component: () =>
      import(
        /* webpackChunkName: "tenant-website-detail" */ "@/views/tenant/website/TenantWebsiteDetailScreen.vue"
      ),
    meta: { requiresAuth: false },
    // website によって ログインの要否が違うという仕様上、TenantWebsiteDetailScreen.vue の checkLogin で担保する
  },

  {
    path: "*",
    redirect: "404",
  },
];

export default routes;
