import Vue from 'vue'

import App from './App.vue'
import router from './router'

import './styles/common.scss'

import registerAll from './vue-modules'

Vue.config.productionTip = false

registerAll()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
