import Vue from 'vue'
import BaseErrorMessage from '@/components/BaseErrorMessage.vue'
import { ErrorDetail } from '@/api'

export type ErrorHandlerModule = {
  errMessage?: string;
  errDetails?: ErrorDetail[];
  networkErr: boolean;
  reloadFunc?: () => void;
  clearAll: () => void;
}

export const registerErrorMessage = () => {
  Vue.component('ErrorMsg', BaseErrorMessage)

  type ErrorHandlerModuleData = {
    errMessage?: string;
    errDetails?: ErrorDetail[];
    networkErr: boolean;
    reloadFunc?: () => void;
  }

  Vue.prototype.$error = new Vue({
    data(): ErrorHandlerModuleData {
      return {
        errMessage: undefined,
        errDetails: undefined,
        networkErr: false,
        reloadFunc: undefined,
      }
    },
    methods: {
      clearAll(): void {
        this.errMessage = undefined
        this.errDetails = undefined
        this.networkErr = false
      }
    }
  })
}
