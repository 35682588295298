import {
  SessionsApiFactory,
  V1TenantSession,
  V1SessionToken,
} from "@vacancorp/enterprise-for-client.vacanservice.com";
import { TenantSession, SessionToken } from "@/models";
import { api, ApiResponse, createApiError } from "./base";

function createSessionToken(
  rawSession?: V1SessionToken
): SessionToken | undefined {
  return new SessionToken({
    accessToken: rawSession?.accessToken,
    refreshToken: rawSession?.refreshToken,
    idToken: rawSession?.idToken,
  });
}

function createTenantSession(
  rawSession?: V1TenantSession
): TenantSession | undefined {
  if (!rawSession) return undefined;

  return new TenantSession({
    userId: rawSession.userId,
    tenantId: rawSession.tenantId,
    role: rawSession.role,
    token: createSessionToken(rawSession.tokens),
  });
}

export const NewSessionsApiFunc = () => {
  const webAdapter = SessionsApiFactory(undefined, api.defaults.baseURL, api);

  return {
    authenticateTenant: async (
      websiteCode: string,
      passcode?: string,
      loginId?: string,
      password?: string
    ): Promise<ApiResponse<TenantSession | undefined>> => {
      let data, err;

      try {
        const d = {
          data: {
            websiteCode: websiteCode,
            passcode: passcode,
            loginId: loginId,
            password: password,
          },
        };
        const res = await webAdapter.authenticateTenantSession(d).catch((e) => {
          throw e;
        });

        if (res.data === null) {
          // idパスワード不要　かつ パスコード必要な時にセッションを作る必要がある。
          data = createTenantSession(undefined);
        } else {
          data = createTenantSession(res.data.tenantUser);
        }
      } catch (e) {
        data = undefined;
        err = createApiError(e);
      }

      return {
        data,
        err,
      };
    },

    refreshToken: async (
      refreshToken?: string
    ): Promise<ApiResponse<SessionToken | undefined>> => {
      let data, err;

      try {
        const res = await webAdapter.refreshToken({
          data: {
            refreshToken: refreshToken,
          },
        });
        data = createSessionToken(res.data.tokens);
      } catch (e) {
        data = undefined;
        err = createApiError(e);
      }

      return {
        data,
        err,
      };
    },

    logout: async (accessToken?: string): Promise<ApiResponse<boolean>> => {
      let data, err;

      try {
        await webAdapter.logout({ data: { accessToken } });
        data = true;
      } catch (e) {
        data = false;
        err = createApiError(e);
      }

      return {
        data,
        err,
      };
    },
  };
};
