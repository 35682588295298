import Vue from "vue";
import { ApiError } from "@/api";

export const registerMixin = () => {
    Vue.mixin({
        methods: {
            $setLoading(loading: boolean): void {
                this.$globalData.loading = loading;
            },

            $setChecked(checked: boolean): void {
                this.$globalData.checked = checked;
            },

            $handleApiError(e?: ApiError, retryfn: (() => void | Promise<void>) | undefined = undefined): void {
                if (e === undefined) {
                    // 余計なメッセージを見せるとユーザーを惑わせるし特に表示しないことにした。APIレスポンス等で原因調査できるし。
                    return;
                }

                if (e.networkErr) {
                    this.$error.networkErr = true;
                    this.$error.reloadFunc = retryfn;
                }
                switch (e.status) {
                    case 400:
                        this.$set(this.$error, "errMessage", e.message);
                        this.$set(this.$error, "errDetails", e.errors);
                        return;
                    case 401:
                        if (this.$auth.refreshToken !== undefined) {
                            this.$auth
                                .refresh()
                                .then((err?: ApiError) => {
                                    if (err) {
                                        this.$auth.clear();
                                        this.$set(this.$error, "errMessage", "入力情報が誤っている可能性があります");
                                        this.$router.push("/login");
                                    } else {
                                        retryfn && retryfn();
                                    }
                                })
                                .catch(() => {
                                    this.$auth.clear();
                                    this.$set(this.$error, "errMessage", "入力情報が誤っている可能性があります");
                                    this.$router.push("/login");
                                });
                        } else {
                            this.$set(this.$error, "errMessage", "入力情報が誤っている可能性があります");
                        }
                        return;
                    case 404:
                        this.$set(this.$error, "errMessage", "存在しないリソースにアクセスしようとしています");
                        this.$router.push("/404");
                        return;
                    default:
                        // 余計なメッセージを見せるとユーザーを惑わせるし特に表示しないことにした。APIレスポンス等で原因調査できるし。
                        return;
                }
            },

            $clearErrors(): void {
                this.$error.clearAll();
            },

            $afterSuccessRequest(callback: () => void, seconds?: number): void {
                if (seconds !== undefined) {
                    setTimeout(callback, seconds * 1000);
                    return;
                }
                callback();
            },
        },
    });
};
