import { registerIcon } from './icon'
import { registerErrorMessage } from './error'
import { registerGlobalData } from './global-data'
import { registerMixin } from './mixin'
import { registerAuth } from './auth'

export default () => {
  registerIcon()
  registerErrorMessage()
  registerGlobalData()
  registerMixin()
  registerAuth()
}
