import Vue from 'vue'
import { TenantWebsite } from '@/models'

export type GlobalDataModule = {
  loading: boolean;
  checked: boolean;
}

export const registerGlobalData = () => {
  type GlobalData = {
    loading: boolean;
    checked: boolean;
  }
  Vue.prototype.$globalData = new Vue({
    data(): GlobalData {
      return {
        loading: false,
        checked: false
      }
    },

    methods: {
    }
  })
}
