import {
  TenantsApiFactory,
  V1GetAuthParametersResponse,
} from "@vacancorp/enterprise-for-client.vacanservice.com";
import { AuthParameter } from "@/models";
import { api, ApiResponse, createApiError } from "./base";

function createAuthParameter(
  rawAuthParameter?: V1GetAuthParametersResponse
): AuthParameter | undefined {
  if (!rawAuthParameter) return undefined;

  return new AuthParameter({
    passcodeAuth: rawAuthParameter.passcodeAuth,
    userAuth: rawAuthParameter.userAuth,
  });
}

export const NewTenantsApiFunc = () => {
  const webAdapter = TenantsApiFactory(undefined, api.defaults.baseURL, api);

  return {
    getAuthParameter: async (
      websiteCode: string
    ): Promise<ApiResponse<AuthParameter | undefined>> => {
      let data, err;

      try {
        const res = await webAdapter.getAuthParameters(websiteCode);

        data = res.status === 204 ? undefined : createAuthParameter(res.data);
      } catch (e) {
        data = undefined;
        err = createApiError(e);
      }

      return {
        data,
        err,
      };
    },
  };
};
