export class VacantStatus {
  id?: string
  total?: number;
  vacant?: number;
  vacantLabel?: string;
  textColor?: string;

  constructor(params?: Partial<VacantStatus>) {
    Object.assign(this, params)
  }
}
