



















import Vue, { PropType } from 'vue'
import DropdownMenu from '@innologica/vue-dropdown-menu'

export type OptionItem = {
  label: string;
  classes: string[];
  clickAction: (op?: OptionItem) => void;
  disabled?: boolean;
}

export default Vue.extend({
  name: 'BaseDropDown',

  components: {
    DropdownMenu
  },

  props: {
    options: Array as PropType<OptionItem[]>,
    value: Boolean as PropType<boolean>
  },

  computed: {
    bindValue: {
      get (): boolean {
        return this.value
      },

      set (): void {
        this.$emit('input', !this.value)
      }
    }
  },

  methods: {
    execAction(op: OptionItem): void {
      if (op.disabled === true) {
        return
      }
      op.clickAction(op)
    },
  }
})
